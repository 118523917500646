import React from "react";
import logoMSN from "../../../assets/images/MSN-PRODUCTION-LOGO.png";
import { Link } from 'react-router-dom'
import {
  // FaPhoneAlt,
  FaLinkedin,
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import {ButtonWa} from "../../../components";
import "../Footer/footer.css";



const Footer = () => {
  return (
    <div>
      <footer className="p-20 footer text-base-100"
      // style={{ backgroundColor: "#EBF1FF" }}
        style={{ backgroundImage: "../../../assets/images/footer-bg.png" }}
      >
        <div>
          <Link to='/'><img src={logoMSN} alt="MSN PRODUCTION" className="w-32 sm:w-46" /></Link>
          <h1 className="text-2xl font-bold text-black ">FVT PRODUCTION</h1>
          {/* <span className="text-accent">20 Vanauley St,<br />
            Toronto, ON M5T 2H4, <br />
            Canada</span> */}
          {/* <div className="flex items-center my-1">
            <FaPhoneAlt className="mr-6 text-2xl text-accent"></FaPhoneAlt>
            <h3 className="font-medium text-accent">+1-416-555-0118</h3>
          </div> */}
          <div className="flex items-center my-1">
            <MdEmail className="mr-6 text-2xl text-accent"></MdEmail>
            <h3 className="font-medium text-accent">info@finversetech.com</h3>
          </div>
        </div>
        {/* Tautan */}
        <div>
          <span className="font-bold text-black">Link</span>   
          <Link
            to="/"
            className="links"
            // link link-hover hover:text-primary text-accent
          >
            <span>Home</span>
          </Link>

          <Link
            to="/services"
            className="links"
          >
            <span>Services</span>
          </Link>

          <Link
            to="/product"
            className="links"
          >
            <span>Products</span>
          </Link>
          <Link
            to="/contact"
            className="links"
          >
            <span>Contact</span>
          </Link>
        </div>
        {/* Fokus & Layanan */}
        <div>
          {/* <span className="font-bold text-accent">Services</span> */}


            {/* <span>Web & Mobile Applications</span>

            Branding & Content Creative
            <span>Automated Billing and Invoicing</span>

            Digital Marketing & Advertising
            <span>Crowdfunding</span> */}

        </div>


      </footer>
      <footer className="px-10 py-6 text-sm text-center text-base-100 border-base-300 bg-neutral ">
        <div className="w-full h-[2px] bg-gray-600"></div>
        <div className="flex flex-col items-center justify-center mt-6 md:flex-row text-accent">
          <p><b>*Offered through partners and licensees</b></p>
          
        </div>
        <div className="flex flex-col items-center justify-center mt-6 md:flex-row text-accent">
          <p>&copy; Copyright {new Date().getFullYear()}, FVT PRODUCTION -  All Rights Reserved</p>
          
        </div>

      </footer>
      <ButtonWa />
    </div>
  );
};

export default Footer;