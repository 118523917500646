import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import visiMisi from "../../../utils/visiMisi"
import {
  headingAnimation,
  // sectionBodyAnimation,
} from "../../../hooks/useAnimation"
// import { BottomLine } from "../../../components";n

const VisiMisi = () => {
  const [ref, inView] = useInView();
  // 
  const [viewDiv, setViewDiv] = useState(false);
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      setViewDiv(true);
    } else {
      setViewDiv(false);
    }
  }, [inView, animation]);
  return (
    <div className="py-16 parent bg-neutral">
      <motion.div
        className="mb-12"
        initial="hidden"
        animate={viewDiv && "visible"}
        variants={headingAnimation}
      >
        {/* <h3 className="text-center text-accent">Goals & Directions</h3> */}
        {/* <h1 className="text-4xl font-semibold text-center"> Our Goal </h1>
        <BottomLine /> */}
      </motion.div>
      <div className="items-center px-5 pt-4 text-center sm:px-20">
                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={viewDiv && "visible"}
                        // variants={sectionBodyAnimation}
                    >
                      
                        <p className="font-medium text-accent">
                        FinVerseTech offers a suite of digital financial services, including streamlined digital payments, and AI-powered investment guidance to optimize customer experiences and simplify complex transactions.
                        </p>
                    </motion.div>
                </div>

      {/* <motion.div
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
        ref={ref}
        initial="hidden"
        animate={viewDiv && "visible"}
        variants={sectionBodyAnimation}
      >
        {visiMisi?.map((visiMisi) => (
          <div
            key={visiMisi.id}
            className={`${visiMisi.id % 2 === 0
              ? "bg-base-100 impactfull-card shadow-lg"
              : "bg-base-100 impactfull-card shadow-lg"
              } rounded-lg p-6 duration-300`}
          >
            <div className="w-40 mx-auto mb-4 text-center">
              {visiMisi.icon}
            </div>
            <h2 className="mb-4 text-2xl font-semibold text-center">
              {visiMisi.title}
            </h2>
            <p className="text-accent">
              {visiMisi.description}
            </p>
          </div>
        ))}
      </motion.div> */}

    </div>
  );
};

export default VisiMisi;