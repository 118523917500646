import { FaBullhorn, FaRegMoneyBillAlt, FaMobileAlt } from "react-icons/fa";

const Services = [
    {
      id: 1,
      title: "Website & Mobile Applications",
      icon: <FaMobileAlt />,
      description:
        "Develop information systems, administrative systems, or promotion systems, based on websites and mobile applications as needed to increase profits and efficiency of business operations in your company. \n ✅ iOS App Development \n ✅ Android App Development \n ✅ UI/UX Web/Mobile App Design \n ✅ WordPress Web Development \n ✅ Custom Web App Development",
    },
    {
      id: 2,
      title: "Crowdfunding",
      icon: <FaBullhorn />,
      description:
        "\nExperience the emerging world of finance with our fintech-powered crowdfunding service. Seamlessly navigate projects and investments, leveraging cutting-edge technology for secure transactions. Join us in shaping a new era of collaborative funding \n ✅ Equity Crowdfunding \n ✅ Reward-based Crowdfunding \n ✅ Debt Crowdfunding \n ✅ Real Estate Crowdfunding \n ✅ Charitable Crowdfunding ",
    },
    {
      id: 3,
      title: "Automated Billing and Invoicing",
      icon: <FaRegMoneyBillAlt />,
      description:
        "Streamline your financial processes with our automated billing and invoicing service. Say goodbye to manual paperwork and errors – our fintech solution ensures accurate and timely invoicing, freeing you to focus on what truly matters \n ✅ Subscription Billing \n ✅ E-commerce Invoicing \n ✅ Enterprise Billing \n ✅ Expense Tracking and Invoicing \n ✅ Multicurrency Invoicing",
    },
  ];

  export default Services;