import React from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { motion } from "framer-motion";
import bannerImg from "../../../assets/images/banner-msn-production.svg";
import "../../../pages/shared/Shared.css";
import { SecondaryBtn } from "../../../components";
import "../Banner/banner.css";

const Banner = () => {
  return (
    <div className="bg">
      <div className="parent min-h-[100vh] flex flex-col-reverse lg:flex-row items-center justify-between">
        <motion.div
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="mb-0 text-4xl font-bold">FinVerseTech</h1>
          <h2 className="my-6 text-2xl font-bold text-primary">
            Empowering Your Financial Success, One Click at a Time!
          </h2>
          {/* </div> */}
          <p className="max-w-md mb-10 font-medium text-accent">
            Welcome to FinVerseTech – Your Gateway to Next Generation Client
            Banking Services! At FinVerseTech, we believe in digital
            transformation of your business. With our customisable and
            personalized approach, we're here to simplify your banking
            experience.
          </p>

          <div className="flex items-center mb-20">
            <a href="https://wa.me/6282125664554" target="blank">

            </a>
            <Link to="/product" className="ml-4">
              <SecondaryBtn>
                <span className="text-button-mobile ">View Products</span>
                <span>
                  <FaAngleRight />
                </span>
              </SecondaryBtn>
            </Link>
          </div>
        </motion.div>
        <motion.div
          className="w-full md:w-1/2"
          initial={{ x: "100vw" }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="order-1 pt-10 lg:order-3 lg:pt-0 md:pt-0 sm:pt-0">
            <img src={bannerImg} alt="Banner FVT PRODUCTION"></img>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Banner;
