const Reviews = [
  {
    id: 1,
    name: "Alexander Williams",
    bio: "CEO PT. NeoPay Ventures",
    img: "https://i.ibb.co/r53shNs/pratama-budiaji.jpg",
    description:
      "The alternative lending approach helped me in a pinch. Their quick approval process and manageable repayment terms made the experience stress-free.😊",
  },
  {
    id: 2,
    name: "Mason Wright",
    bio: "WealthBridge Tech",
    img: "https://i.ibb.co/mJJ8sm1/bapak-kurniawan.jpg",
    description:
      "Impressed with the seamless user experience provided by Finverse Tech. The secure transactions and real-time updates are game-changers. 👍👍",
  },
  {
    id: 3,
    name: "Nathan Taylor & Sophia Taylor",
    bio: "Finova Financial",
    img: "https://i.ibb.co/dgLRQZw/mempelai-1.jpg",
    description:
      "Finverse Tech's platform simplified international money transfers for me. The exchange rates were competitive, and the transaction speed was impressive. 🥰😍😍",
  },
  {
    id: 4,
    name: "Ava Miller",
    bio: "Founder, SecureCash Systems",
    img: "https://i.ibb.co/FVnbpjM/ibu-silvana.jpg",
    description:
      "The flexible payment options have made budgeting much easier. Their 'pay later' feature and customizable payment plans are a game-changer. 👌😁",
  },
];

export default Reviews;
