import React from "react";
import { TentangKami, VisiMisi} from "../../components";
// , Tim 

const Tentang = () => {
  return (
    <div className="pt-16">
      <TentangKami />
      <VisiMisi />
      {/* <Tim /> */}
    </div>
  );
};

export default Tentang;